import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import styles from "./styles.module.css";
import { MonthPicker } from "./MonthPicker";
import {getPointSales,getPointSalesTotal} from "../../api/points";
import moment from "moment";
import {PriceLabel} from "./PriceLabel";

const PriceViewer = ({ isShow = true, posId }) => {
	const [selectedMonth, setSelectedMonth] = useState();
	const [todayPrice, setTodayPrice] = useState(0);
	const [monthPrice, setMonthPrice] = useState(0);
	const [fullPrice, setFullPrice] = useState(0);
	const [isTodayLoading, setIsTodayLoading] = useState(false);
	const [isMonthLoading, setIsMonthLoading] = useState(false);
	const [isTotalLoading, setIsTotalLoading] = useState(false);

	// На сегодня
	useEffect(() => {
		let interval;


		if (posId) {
			setIsTodayLoading(true);
			( async () => getTodayPrice())();
			( async () => getFullPrice())();

			interval = setInterval(async () => {
				await getTodayPrice();
			}, '5000');
		}

		return () => {
			clearInterval(interval);
		}
	}, [posId]);

	// На месяц
	useEffect(() => {
		if (posId && selectedMonth?.month)
			(async () => getMonthPrice())();

	}, [posId, selectedMonth]);



	const genMonth = (month) =>
		month.toString().length > 1 ? month : `0${month}`;

	const getTodayPrice = async () => {
		try {
			const payload = posId === -1 ? {} : { posId };
			const { data: { revenue = 0 } } = await getPointSales(payload);

			//if (Math.ceil(revenue) !== todayPrice && monthPrice)
				//setMonthPrice((prev) => prev + todayPrice);

			//if (Math.ceil(revenue) !== todayPrice && fullPrice)
				//setFullPrice((prev) => prev + fullPrice);

			setTodayPrice(Math.ceil(revenue));
		} catch {
			setTodayPrice(0);
		} finally {
			setIsTodayLoading(false);
		}
	};

	const getMonthPrice = async () => {
		try {
			setIsMonthLoading(true);
			const selectedYear = selectedMonth.year;
			const month = selectedMonth.month;
			const lastDayOfMonth = new Date(selectedYear, month, 0).getDate();
			const dateStart = `${selectedYear}-${genMonth(month)}-01`;
			const dateEnd = `${selectedYear}-${genMonth(month)}-${lastDayOfMonth}`;

			const payload = { dateEnd, dateStart };

			if (posId !== -1 ) payload.posId = posId;

			const { data: { revenue = 0 } } = await getPointSales(payload);

			setMonthPrice(Math.ceil(revenue));
		} catch {
			setMonthPrice(0);
		} finally {
			setIsMonthLoading(false);
		}
	}

	const getFullPrice = async () => {
		try {
			setIsTotalLoading(true);

			const payload = {};

			if (posId !== -1 ) payload.posId = posId;

			const { data: { revenue = 0 } } = await getPointSalesTotal(payload);

			setFullPrice(Math.ceil(revenue));
		} catch {
			setFullPrice(0);
		} finally {
			setIsTotalLoading(false);
		}
	}

	return (
		<div className={clsx(styles.priceBlock, !isShow && styles.hidden )}>
			<table>
				<tbody>
					<tr>
						<td>Сегодня</td>
						<td><PriceLabel price={todayPrice} isLoading={isTodayLoading} /></td>
					</tr>
					<tr>
						<td>
							<MonthPicker onSelectMonth={setSelectedMonth} posId={posId} />
						</td>
						<td><PriceLabel price={monthPrice} isLoading={isMonthLoading} /></td>
					</tr>
					<tr>
						<td>С открытия</td>
						<td><PriceLabel price={fullPrice} isLoading={isTotalLoading} /></td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export { PriceViewer };
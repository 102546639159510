import React, {useEffect, useState} from 'react';
import styles from "./styles.module.css";
import Chevron from '../../images/chevron-down.svg';
import clsx from "clsx";
import { getPointsList } from "../../api/points";

const CitySelect = ({ callbackShow = (f) => f, onSelectPoint = (f) => f, onChangeVideo = (f) => f }) => {
	const [points, setPoints] = useState([]);
	const [selectedPoint, setSelectedPoint] = useState(-1);
	const [selectedVideo, setSelectedVideo] = useState();
	const [isShow, setIsShow] = useState(false);

	useEffect(() => {
		(async () => {
			const { data } = await getPointsList();

			if (data) {
				setPoints(data);
				setSelectedPoint( -1);
			}

		})();
	}, []);

	useEffect(() => {
		callbackShow(isShow);
	}, [isShow]);

	useEffect(() => {
		if (selectedPoint) onSelectPoint(selectedPoint);
	}, [selectedPoint])

	useEffect(() => {
		onChangeVideo(selectedVideo);
	}, [selectedVideo])

	const handleSelectPoint = (id, videoUrl = undefined) => {
		setSelectedPoint(id);
		setSelectedVideo(videoUrl);
		setIsShow(false);
	};

	return (
		<div className={styles.cityBlock}>
			<div className={styles.cityText}>
				<p>ВЫРУЧКА</p>
				<p>Выберите точку</p>
			</div>
			<div className={clsx(styles.citySelect, isShow && styles.active)} onClick={() => setIsShow(!isShow)}>
				{selectedPoint !== -1 ? points.find((el) => el.id === selectedPoint)?.name || null : 'Все точки'}
				<img src={Chevron} alt="chevron"/>
			</div>
			<div className={styles.dropDown}>
				<ul>
					{selectedPoint !== -1 && <li onClick={() => handleSelectPoint(-1)}>Все точки</li>}
					{points.filter(({ id }) => id !== selectedPoint).map(({ name, id, video_url }) => (
						<li key={id} onClick={() => handleSelectPoint(id, video_url) }>{ name }</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export { CitySelect };
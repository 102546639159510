import React, {useEffect, useState} from 'react';
import styles from "./styles.module.css";
import {CitySelect} from "../CitySelect";
import LogoSvg from "../../images/logo.svg";
import SmallLogoSvg from "../../images/small-logo.svg";
import {YoutubeMobileVideo} from "../YoutubeMobileVideo";
import {PriceViewer} from "../PriceViewer";

const Aside = ({ onChangeVideo = (f) => f, onChangePoint = (f) => f }) => {
	const [isShowDropdown, setIsShowDropdown] = useState(false);
	const [selectedPoint, setSelectedPoint] = useState();
	const [selectedVideo, setSelectedVideo] = useState();
	const [windowWidth, setWindowWidth] = useState(0);

	useEffect(() => {
		setWindowWidth(window.innerWidth);
	}, []);

	useEffect(() => {
		onChangeVideo(selectedVideo);
	}, [selectedVideo]);

	useEffect(() => {
		if (selectedPoint) onChangePoint(selectedPoint);
	}, [selectedPoint])

	const handleBitrix = () => {
		const form = 	document.getElementsByClassName('b24-form')[0];
		const popup = document.getElementsByClassName('b24-form-wrapper')[0];

		if (form.classList.contains('showed')) {
			form.style.display = 'none';
			form.classList.remove('showed')
		} else {
			form.style.display = 'flex';
			form.classList.add('showed');
		}



		form.removeEventListener("click", () => {
			form.style.display = 'none';
		});

		popup.removeEventListener("click", (e) => {
			e.stopPropagation();
		});

		form.addEventListener("click", () => {
			form.style.display = 'none';
			if (form.classList.contains('showed')) form.classList.remove('showed')
		});

		popup.addEventListener("click", (e) => {
			e.stopPropagation();
		})

	}

	return (
		<aside className={styles.aside}>
			<div className={styles.logoBlock}>
				<div>
					<img src={LogoSvg} alt="logo" />
					<p>ФРАНЧАЙЗИНГ</p>
				</div>
				<div className={styles.marketingText}>
					<p>Франшиза реально<br/> работающих пиццерий</p>
					<hr/>
				</div>
			</div>

			{ windowWidth <=600 && <YoutubeMobileVideo videoUrl={selectedVideo} posId={selectedPoint} /> }

			<div className={styles.content}>
				<div className={styles.cityBlock}>
					<CitySelect callbackShow={setIsShowDropdown} onSelectPoint={setSelectedPoint} onChangeVideo={setSelectedVideo} />
				</div>

				<PriceViewer isShow={!isShowDropdown} posId={selectedPoint} />

				<p>Выручка и видео в реальном времени<br/> из нашей CRM</p>
				<p>Оформить заказ можно на сайте <a href="https://eepizza.ru">eepizza.ru</a></p>

				<div className={styles.bitrixBlock}>
					<button type="button" className={styles.bitrixBtn} onClick={handleBitrix}>
						<img src={SmallLogoSvg} alt="small-logo-pizza"/>
						Узнать подробности
					</button>
				</div>
			</div>

		</aside>
	);
};

export { Aside };
import React from 'react';
import Preloader from '../../../images/preloader.gif';
import clsx from "clsx";
import style from './styles.module.css';

const PriceLabel = ({ price, isLoading = false }) => {

	const getFormatPrice = (val = 0) =>
		Intl.NumberFormat('ru-RU', {
			style: 'currency',
			currency: 'RUB',
			currencyDisplay: 'symbol'
		}).format(val)

	return (
		<span className={clsx(style.container, isLoading && style.loading)}>
			{!isLoading ? getFormatPrice(price) : <img src={Preloader} style={{ width: '15px' }} alt="preloader"/> }
		</span>
	);
};

export { PriceLabel };
import axios from "axios";

const { REACT_APP_API } = process.env;

export const getPointsList = async () => {
	return await axios.get(`${REACT_APP_API}/api/aggregator/points_of_sale/`);
}

export const getPointSales = async ({ posId, dateStart, dateEnd }) => {
	let query = ''; 

	if (!dateStart && !dateEnd)
		query = `/api/manager/dashboard/eefr_to_day/`;
	else
		query = `/api/manager/dashboard/eefr_range/`;

	return await axios.get(`${REACT_APP_API}${query}`, {
		timeout: 60000,
		params: {
			posId,
			date_start: dateStart,
			date_end: dateEnd,
		}
	});
}
export const getPointSalesTotal = async ({ posId }) => {

 

	return await axios.get(`${REACT_APP_API}/api/manager/dashboard/eefr_total/`, {
		timeout: 60000,
		params: {
			posId
		}
	});
}
import React from 'react';
import styles from "./styles.module.css";
import { YoutubeFrame } from "../YoutubeFrame";

const YoutubeMobileVideo = ({ videoUrl, posId = -1 } ) => {
	return (
		<div className={styles.mobileVideoBackground}>
			{posId === -1 || !videoUrl
				? (
					<div className={styles.defaultVideo}>
						<div dangerouslySetInnerHTML={{ __html: `
							<video
								loop
								muted
								autoplay
								playsinline
								src="/eepizza.mp4"
							/>,
						` }}>
						</div>
					</div>
				)
				: <iframe src={videoUrl} frameBorder="0" allowFullScreen></iframe>
			}
		</div>
	);
};

export { YoutubeMobileVideo };
import React, {useEffect, useState} from 'react';
import Chevron from "../../../images/chevron-down.svg";
import styles from './styles.module.css';
import clsx from "clsx";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";

const MonthPicker = ({ posId, onSelectMonth = (f) => f }) => {
	const [isShowDropdown, setIsShowDropdown] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState({
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1
	});
	const [selectedYear, setSelectedYear] = useState();
	const [years, setYears] = useState([]);
	const firstYear = 2021;
	const endYear = new Date().getFullYear();
	const month = [
		{
			text: 'Январь',
			number: 1
		},
		{
			text: 'Февраль',
			number: 2
		},
		{
			text: 'Март',
			number: 3
		},
		{
			text: 'Апрель',
			number: 4
		},
		{
			text: 'Май',
			number: 5
		},
		{
			text: 'Июнь',
			number: 6
		},
		{
			text: 'Июль',
			number: 7
		},
		{
			text: 'Август',
			number: 8
		},
		{
			text: 'Сентябрь',
			number: 9
		},
		{
			text: 'Октябрь',
			number: 10
		},
		{
			text: 'Ноябрь',
			number: 11
		},
		{
			text: 'Декабрь',
			number: 12
		}
	]

	useEffect(() => {
		for (let i = firstYear; i <= endYear; i++)
			setYears((prev) => [...prev, { year: i, month }]);

		return () => {
			setYears([]);
		}

	}, [])

	useEffect(() => {
		setSelectedMonth({
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1
		})
	}, [posId]);

	useEffect(() => {
		if (selectedMonth?.month) onSelectMonth(selectedMonth);
	}, [selectedMonth]);

	useEffect(() => {
		if (!isShowDropdown) setSelectedYear(undefined);
	}, [isShowDropdown])


	const handleSelectMonth = (number) => {
		setSelectedMonth({ year: selectedYear, month: number });
		setIsShowDropdown(false);
	}

	const monthBlock =  (
		<div>
			<button
				className={styles.yearBtn}
				onClick={() => setSelectedYear(undefined)}
			>
				<ArrowLeftIcon />
				{selectedYear}
			</button>
			<ul className={styles.itemList}>
				{month.map(({ number, text }) => (
					<li key={number} onClick={() => handleSelectMonth(number)}>{text}</li>
				))}
			</ul>
		</div>
	);

	const yearBlock = (
		<ul className={styles.itemList}>
			{years.map((el) => (
				<li key={el.year} onClick={() => setSelectedYear(el.year)}>{el.year}</li>
			))}
		</ul>
	);

	return (
		<div className={styles.container}>
			<span onClick={() => setIsShowDropdown(!isShowDropdown)}>
				{(month.find((el) => el.number === selectedMonth.month))?.text} ({selectedMonth.year})
				<img src={Chevron} alt="chevron" />
			</span>
			<div className={clsx(styles.list, isShowDropdown && styles.active)}>
				{selectedYear ? monthBlock : yearBlock}
			</div>
		</div>
	);
};

export { MonthPicker };
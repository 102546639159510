import React from 'react';
import styles from "./styles.module.css";
import clsx from "clsx";

const YoutubeVideo = ({ videoUrl, posId = -1 }) => {


	return (
		<div className={clsx(styles.videoWrapper, (posId !== -1 && videoUrl) && styles.videoWrapperResp )}>
			{posId === -1 || !videoUrl
				? (
					<video disablePictureInPicture loop autoPlay muted defaultMuted playsinline>
						<source
							src="/eepizza.mp4"
							type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
						/>
					</video>
				)
			  : <iframe src={videoUrl} frameBorder="0" allowFullScreen></iframe>
			}
		</div>
	);
};

export { YoutubeVideo };
import React, {useEffect, useState} from 'react';
import styles from './styles.module.css';
import { Aside } from "../../components/Aside";
import { YoutubeVideo } from "../../components/YoutubeVideo";
import clsx from "clsx";

const App = () => {
	const [selectedVideo, setSelectedVideo] = useState();
	const [selectedPosId, setSelectedPosId] = useState();
	const [windowWidth, setWindowWidth] = useState(0);

	useEffect(() => {
		setWindowWidth(window.innerWidth);
	}, []);

	return (
		<div className={clsx(styles.wrapper, !selectedVideo && styles.default)}>
			{windowWidth > 600 && <YoutubeVideo videoUrl={selectedVideo} posId={selectedPosId} /> }
			<div className={styles.container}>
				<main className={styles.body}>
					<Aside onChangeVideo={setSelectedVideo} onChangePoint={setSelectedPosId} />
				</main>
			</div>
		</div>
	);
};

export default App;